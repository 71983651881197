import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const AppCard = ({ app }) => {
  const CardWrapper = app.externalLink
    ? motion.a // Use motion.a for external links
    : motion(Link);

  const cardProps = app.externalLink
    ? { href: app.externalLink, target: '_blank', rel: 'noopener noreferrer', style: { textDecoration: 'none' } }
    : { to: `/apps/${app.id}`, style: { textDecoration: 'none' } };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <CardWrapper {...cardProps}>
        <Card
          sx={{
            background: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(10px)',
            borderRadius: '16px',
            overflow: 'hidden',
            transition: 'transform 0.3s ease-in-out',
            height: '100%',
            '&:hover': {
              transform: 'translateY(-10px)',
              cursor: 'pointer',
            }
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <CardMedia
              component="img"
              height="200"
              image={app.image}
              alt={app.name}
            />
            {/* App Icon Overlay */}
            <Box
              sx={{
                position: 'absolute',
                left: '20px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '80px',
                height: '80px',
                borderRadius: '16px',
                overflow: 'hidden',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              }}
            >
              <img
                src={app.icon}
                alt={`${app.name} icon`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Box>
          <CardContent>
            <Typography 
              variant="h5" 
              component="div" 
              sx={{ 
                color: '#fff', 
                mb: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {app.name}
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                color: '#aaa',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                lineHeight: 1.5,
                height: '3em'
              }}
            >
              {app.description}
            </Typography>
          </CardContent>
        </Card>
      </CardWrapper>
    </motion.div>
  );
};

export default AppCard;