import { motion } from 'framer-motion';
import { Typography, Button, Container, Box } from '@mui/material';

const Home = () => {
  return (
    <Container className="page-container">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* Title */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2.5rem', md: '4rem' },
              fontWeight: 'bold',
              mb: 4,
              textAlign: 'center',
              background: 'linear-gradient(45deg, #fff, #999)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Welcome to Omnilabs
          </Typography>
        </motion.div>

        {/* Text Sections */}
        <Box sx={{ mb: 6 }}>
          <Typography
            variant="h6"
            component={motion.p}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            sx={{
              color: '#999',
              lineHeight: 1.8,
              mb: 4,
              textAlign: 'left',
              maxWidth: '1100px',
              margin: '0 auto',
            }}
          >
            We are a team of passionate innovators dedicated to creating exceptional consumer products that leave a lasting impact. At OmniLabs LTD, we blend art and science to develop solutions that not only meet but exceed expectations.
          </Typography>

          <Typography
            variant="h6"
            component={motion.p}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            sx={{
              color: '#999',
              lineHeight: 1.8,
              mb: 4,
              textAlign: 'left',
              maxWidth: '1100px',
              margin: '0 auto',
            }}
          >
            With a focus on Premium iOS Apps & Expert Consulting, we leverage cutting-edge technology to build seamless, high - performance mobile experiences.
          </Typography>

          <Typography
            variant="h6"
            component={motion.p}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
            sx={{
              color: '#999',
              lineHeight: 1.8,
              textAlign: 'left',
              maxWidth: '1100px',
              margin: '0 auto',
            }}
          >
            Innovation is our driving force. We strive for perfection in every project, aiming to make the world a better place through groundbreaking products and services that redefine user experiences and drive meaningful progress.
          </Typography>
        </Box>

        {/* Buttons */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1 }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#fff',
              color: '#121212',
              padding: '12px 24px',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
              },
            }}
            href="/apps"
          >
            View Our Apps
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderColor: '#fff',
              color: '#fff',
              padding: '12px 24px',
              '&:hover': {
                borderColor: 'rgba(255, 255, 255, 0.8)',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
            href="/contact"
          >
            Contact Us
          </Button>
        </motion.div>
      </motion.div>
    </Container>
  );
};

export default Home; 