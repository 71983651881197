import { useParams } from 'react-router-dom';
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import ImageGallery from '../components/ImageGallery';
import { getGalleryImages } from '../utils/galleryHelper';

const AppDetails = () => {
  const { id } = useParams();

  // App data - you might want to move this to a separate data file
  const apps = {
    aware360: {
      name: "Aware360",
      subtitle: "Your Personal Safety Companion",
      description: "Stay safe anywhere with real-time risk assessments and global coverage. Explore confidently with Aware360!",
      fullDescription: "Aware360 is the ultimate tool for real-time awareness of your surroundings. Whether exploring new locations or navigating daily life, it empowers you with insights to stay safe. Using location data, it analyzes factors like population density, safety indexes, weather, and more to calculate potential risks. Aware360 covers personal, environmental, health, and travel safety risks, providing detailed reports, interactive maps, and real-time updates. With global coverage, privacy-first design, and a one-time purchase model, Aware360 is your trusted safety companion for any adventure.",
      heroImage: "",
      icon: "/app-images/aware360/aware360-icon.png",
      youtubeUrl: "",
      appStoreUrl: "https://apps.apple.com/us/app/aware360/id6575386606"
    },
    paka: {
      name: "PAkA!",
      subtitle: "Unleash Your Creativity",
      description: "Create procedurally generated content on the fly.",
      fullDescription: "PAkA! stands for Procedural Artwork Arranger. PAkA! is a software program which allows you to create procedurally generated content on the fly. Simply said - you can get professional-looking pattern like designs with virtually no effort. You just need to select a bunch of images from our huge library, dial in some settings and press a button (Generate). Yes, it is that easy! PAkA!'s advanced algorithm guarantees unique results each time. The generated designs can be exported as JPEG, transparent PNG or editable PDF. This pattern design software is perfect for cloth or garment printing.",
      heroImage: "",
      icon: "/app-images/paka/paka-icon.png",
      youtubeUrl: "https://www.youtube.com/embed/Ro5cOQm6bmA",
      appStoreUrl: "https://apps.apple.com/us/app/paka-pattern-maker/id1586591076"
    },
    bombsquad: {
      name: "Bombsquad - Defuse the bomb",
      subtitle: "Can you defuse a bomb?",
      description: "Join the Bomb Squad and defuse bombs like a pro!",
      fullDescription: "Bombsquad – Defuse The Bomb is a bomb-defusing puzzle game, one of the first games in this genre with great graphics and artwork. Released after 2 years of hard work in April 2013, it has been updated more than 20 times and downloaded more than 100 million times, reaching top of the App Store charts several times. The game consists of 32 levels of different complexity, featuring 5 different defusing tools such as wire cutters, screw driver, jumper wire, multi-meter and radio frequency jammer. The game is both challenging and entertaining and is available for iOS, MacOS and Android devices.",
      heroImage: "",
      icon: "/app-images/bombsquad/bombsquad-icon.png",
      youtubeUrl: "https://www.youtube.com/embed/9GaQXzJaM-A",
      appStoreUrl: "https://itunes.apple.com/us/app/the-bombsquad/id594851821",
      reviews: [
        {
          author: "OfficialJackle",
          text: "Best Game ever! The graphics are amazing and everything is perfection!"
        },
        {
          author: "Gustavodl1234",
          text: "This games rocks if you have the knowledge and understanding of electronics. Trust me you'll enjoy it"
        },
        {
          author: "andriiburka",
          text: "I think is cool different game. Very nice graphics and ..i like it at all"
        },
        {
          author: "Seabag2828",
          text: "Very challenging game. This game blows my mind."
        },
        {
          author: "Michlyn9",
          text: "I love this game I love the graphics and it's so challenging it's actually hard for me, lovin it"
        },
        {
          author: "Lectong",
          text: "Very good looking, ton of levels and perfect sound."
        },
        {
          author: "MJLeleaux",
          text: "Don't bother if you don't know how to apply strong deductive reasoning. If you want a challenge and know how to use your head, it's a pretty cool game."
        }
      ]
    },
    bombsquad3d: {
      name: "Bombsquad 3D",
      subtitle: "Can you defuse a bomb?",
      fullDescription: "Bombsquad 3D puts you on the frontline as an elite bomb disposal expert. Your mission is to defuse intricate explosives designed by cunning terrorists, using specialized tools and relying on your wits and steady nerves. Tackle 20 challenging missions with escalating complexity, featuring stunning 3D graphics, realistic bomb mechanics, and interactive elements like buttons and lids. Sharpen your skills through detailed training exercises and explore an immersive bomb gallery. Stay focused—one wrong move, and it's game over!",
      heroImage: "",
      icon: "/app-images/bombsquad3d/bombsquad3d-icon.png",
      youtubeUrl: "https://www.youtube.com/embed/ll0RSMlQtVw",
      appStoreUrl: "https://apps.apple.com/us/app/bombsquad-3d/id1507817267"
    },
    homesoundz: {
      name: "HomeSoundz",
      subtitle: "Sleep well, kids!",
      fullDescription: "HomeSoundz is a carefully crafted white-noise app designed by a parent who understands sleepless nights firsthand. Unlike artificial sounds, this app uses authentic recordings of familiar household noises, captured with professional audio gear, to soothe your child gently to sleep. With 15 high-quality, seamless looping sounds - each accompanied by a picture of its real-life source - your child will feel safe, relaxed, and comforted. Easy-to-use features include gentle start and stop transitions, customizable timers, volume control, and reliable background playback. Give your children (and yourself!) the restful nights you've been dreaming of.",
      heroImage: "",
      icon: "/app-images/homesoundz/homesoundz-icon.png",
      youtubeUrl: "",
      appStoreUrl: "https://apps.apple.com/us/app/home-soundz/id6443932896"
    },
    pandventure: {
      name: "Pandventure Run",
      subtitle: "Fitness Adventure Game",
      fullDescription: "Pandventure Run is an exciting and charming endless runner where you guide a lovable, chubby panda on an epic fitness journey! Dash, jump, and dodge your way through 15 beautifully handcrafted obstacle courses — ranging from serene islands and lush bamboo forests to mystical mountain monasteries. Help your panda eat healthy snacks, avoid tempting junk food, and unlock powerful boosters to improve your agility. With delightful graphics, easy controls, engaging challenges, and rewarding gameplay, Pandventure Run promises endless fun and a healthy message: get fit, stay agile, and help your panda buddy become the ultimate runner!",
      heroImage: "",
      icon: "/app-images/pandventure/pandventure-icon.png",
      youtubeUrl: "https://www.youtube.com/embed/ySFnu2jqxfY",
      appStoreUrl: "https://apps.apple.com/us/app/pandventure-run-fitness-panda/id1570283203"
    },
    myvrx: {
      name: "My VRX",
      subtitle: "Virtual Event Platform",
      fullDescription: "My VRX offers a stunningly immersive, fully interactive 3D environment for virtual exhibitions, product presentations, classrooms, or stores. Navigate freely around lifelike booths, explore detailed displays, and experience state-of-the-art graphics and spatial sound — all from the comfort and safety of your home. Built by us this solution features secure, multi-platform access, rich analytics for visitor behavior, and complete customization tailored to your vision. Elevate your virtual experience — no limits, no risks, just endless possibilities.",
      heroImage: "",
      icon: "/app-images/myvrx/myvrx-icon.png",
      youtubeUrl: "",
      appStoreUrl: "https://apps.apple.com/us/app/my-vrx/id1537293970"
    }
  };

  const app = apps[id];

  // Helpful for debugging
  console.log(app?.heroImage);

  if (!app) {
    return <div>App not found</div>;
  }

  // Get gallery images dynamically
  const galleryImages = getGalleryImages(id);

  const AppStoreButton = () => (
    <Button
      component="a"
      href={app.appStoreUrl}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        minWidth: 'auto',
        padding: 0,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <img
        src="/download-app-store.svg"
        alt="Download on App Store"
        style={{ height: '68px' }}
      />
    </Button>
  );

  return (
    <Container className="page-container">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Header Section */}
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Box
            sx={{
              mb: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%'
            }}
          >
            {/* Icon and Title/Subtitle Column */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                mb: 3
              }}
            >
              <img
                src={app.icon}
                alt={app.name}
                style={{
                  width: 120,
                  height: 120,
                  borderRadius: 24,
                  marginRight: 24
                }}
              />
              <Box sx={{ textAlign: 'left', flex: 1 }}>
                <Typography variant="h3" className="heading" sx={{ mb: 0.5 }}>
                  {app.name}
                </Typography>
                <Typography variant="h5" sx={{ color: '#999' }}>
                  {app.subtitle}
                </Typography>
              </Box>
            </Box>

            {/* Download Button */}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                mb: 2,
              }}
            >
              <AppStoreButton />
            </Box>

            {/* About Section */}
            <Typography
              variant="h6"
              sx={{
                color: '#999',
                lineHeight: 1.8,
                mb: 2,
                width: '100%',
                textAlign: 'left'
              }}
            >
              {app.fullDescription}
            </Typography>

            {/* Hero Image Section */}
            {app.heroImage && (
              <Box sx={{ mb: 8 }}>
                <img
                  src={app.heroImage}
                  alt={`${app.name} Hero`}
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: 16,
                    objectFit: 'fill',
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>

        {/* Video Section */}
        {app.youtubeUrl && (
          <Box sx={{ mb: 8 }}>
            <Box
              sx={{
                position: 'relative',
                paddingBottom: '56.25%', // 16:9 aspect ratio
                height: 0,
                overflow: 'hidden',
                borderRadius: 4,
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  borderRadius: 16,
                }}
                src={app.youtubeUrl}
                title={`${app.name} Demo`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Box>
          </Box>
        )}

        {/* Gallery Section */}
        <Box sx={{ mb: app.youtubeUrl ? 8 : 4 }}>
          <ImageGallery images={galleryImages} />
        </Box>

        {/* Reviews Section */}
        {app.reviews && (
          <Box sx={{ mb: 8 }}>
            <Typography variant="h4" className="heading" sx={{ mb: 4 }}>
              What users say
            </Typography>
            <Grid container spacing={2}>
              {app.reviews.map((review, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box
                    sx={{
                      background: 'rgba(255, 255, 255, 0.03)',
                      backdropFilter: 'blur(10px)',
                      borderRadius: '16px',
                      p: 2,
                      height: 'auto',
                      minHeight: '120px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                        background: 'rgba(255, 255, 255, 0.05)',
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#fff',
                        mb: 1,
                        fontStyle: 'italic',
                        lineHeight: 1.4,
                        flexGrow: 1,
                      }}
                    >
                      "{review.text}"
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: '#999',
                        fontWeight: 'bold'
                      }}
                    >
                      - {review.author}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </motion.div>
    </Container>
  );
};

export default AppDetails;
