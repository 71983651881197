import { Container, Grid } from '@mui/material';
import AppCard from '../components/AppCard';

const Apps = () => {
  const apps = [
    {
      id: "aware360",
      name: "Aware360",
      description: "Stay safe anywhere with real-time risk assessments and global coverage.",
      image: "/app-images/aware360/aware360.png",
      icon: "/app-images/aware360/aware360-icon.png"
    },
    {
      id: "paka",
      name: "PAkA!",
      description: "Create procedurally generated content on the fly.",
      image: "/app-images/paka/paka.png",
      icon: "/app-images/paka/paka-icon.png"
    },
    {
      id: "bombsquad",
      name: "Bombsquad - Defuse the bomb",
      description: "Join the Bomb Squad and defuse bombs like a pro!",
      image: "/app-images/bombsquad/bombsquad.png",
      icon: "/app-images/bombsquad/bombsquad-icon.png"
    },
    {
      id: "bombsquad3d",
      name: "Bombsquad 3D",
      description: "Start defusing bombs in 3D!",
      image: "/app-images/bombsquad3d/bombsquad3d.png",
      icon: "/app-images/bombsquad3d/bombsquad3d-icon.png"
    },
    {
      id: "homesoundz",
      name: "HomeSoundz",
      description: "Get your kids to sleep with sounds from the house.",
      image: "/app-images/homesoundz/homesoundz.png",
      icon: "/app-images/homesoundz/homesoundz-icon.png"
    },
    {
      id: "pandventure",
      name: "Pandventure Run",
      description: "Join the cute panda on a fitness adventure!",
      image: "/app-images/pandventure/pandventure.png",
      icon: "/app-images/pandventure/pandventure-icon.png"
    },
    {
      id: "myvrx",
      name: "My VRX",
      description: "Welcome to virtual event demo.",
      image: "/app-images/myvrx/myvrx.png",
      icon: "/app-images/myvrx/myvrx-icon.png"
    }
  ];

  return (
    <Container className="page-container" sx={{ pb: 8 }}>
      <Grid container spacing={4}>
        {apps.map((app, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <AppCard app={app} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Apps; 