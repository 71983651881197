import { Container, Typography, Grid, Box } from '@mui/material';
import AppCard from '../components/AppCard';
import logo from '../omnilabs.png';

const About = () => {
  return (
    <Container className="page-container" sx={{ pb: 8 }}>
      <Typography
        className="heading page-title"
        variant="h2"
        sx={{
          fontSize: { xs: '2.5rem', md: '4rem' },
          fontWeight: 'bold',
          mb: 4,
          textAlign: 'center',
          background: 'linear-gradient(45deg, #fff, #999)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        About Omnilabs LTD
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 6 }}>
        <img
          src={logo}
          alt="Omnilabs Logo"
          style={{ height: '80px', objectFit: 'contain' }}
        />
      </Box>

      <Typography
        className="description page-content"
        variant="body1"
        sx={{
          color: '#999',
          lineHeight: 1.8,
          mb: 12, // Further increased spacing
          textAlign: 'left',
          maxWidth: '1100px',
          margin: '0 auto',
        }}
      >
        Founded in 2013, Omnilabs LTD is driven by one simple motto: "We Make Cool Stuff." 
        With over 15 years of experience in creating innovative mobile apps, engaging games, 
        and interactive software solutions, we blend cutting edge technology with boundless creativity. 
        Our passionate team is committed to excellence, quality, and pushing the boundaries of what's possible. 
        At Omnilabs, every project is an opportunity to turn imaginative ideas into reality delivering exceptional 
        experiences tailored exactly to your needs.
      </Typography>

      <Typography
        className="heading"
        variant="h3"
        sx={{
          fontSize: { xs: '2rem', md: '3rem' },
          fontWeight: 'bold',
          mt: 8, // Add spacing above the title
          mb: 4,
          textAlign: 'center',
          background: 'linear-gradient(45deg, #fff, #999)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Our Partners
      </Typography>
      <Grid container spacing={4} sx={{ mb: 8 }}>
        <Grid item xs={12} md={4}>
          <AppCard
            app={{
              id: 'southstylemovies',
              name: 'SouthStyleMovies',
              description: 'Multimedia design studio with a strong focus on aerial photography and 3D visualizations.',
              image: '/app-images/southstylemovies/southstylemovies.jpg',
              icon: '/app-images/southstylemovies/southstylemovies-icon.jpg',
              externalLink: 'https://www.ssmbg.com',
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppCard
            app={{
              id: 'evotion',
              name: 'Evotion',
              description: 'Digital studio with a passion for creating motion graphics, dedicated to delivering stunning visuals.',
              image: '/app-images/evotion/evotion.jpg',
              icon: '/app-images/evotion/evotion-icon.jpg',
              externalLink: 'https://www.evotion.space',
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppCard
            app={{
              id: 'southstylerecords',
              name: 'SouthStyleRecords',
              description: 'Old school record label focused on hip-hop, electronic music, game and movie soundtracks.',
              image: '/app-images/southstylerecords/southstylerecords.jpg',
              icon: '/app-images/southstylerecords/southstylerecords-icon.png',
              externalLink: 'https://www.southstylerecords.com',
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;