import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, List, ListItem, ListItemText, useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../omnilabs.png';

const Navbar = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  window.addEventListener('scroll', () => {
    setIsScrolled(window.scrollY > 20);
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: 'Home', path: '/' },
    { text: 'Apps', path: '/apps' },
    { text: 'About', path: '/about' },
    { text: 'Contact', path: '/contact' },
  ];

  const drawer = (
    <Box sx={{ 
      width: '100%', 
      bgcolor: 'rgba(18, 18, 18, 0.95)', 
      color: 'white',
      height: 'auto',
      overflow: 'hidden'
    }}>
      <List sx={{ py: 2, width: '100%', overflow: 'hidden' }}>
        {menuItems.map((item) => (
          <ListItem 
            button 
            key={item.text} 
            component={Link} 
            to={item.path}
            onClick={handleDrawerToggle}
            sx={{
              margin: '8px 16px',
              borderRadius: '4px',
              width: 'auto',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              }
            }}
          >
            <ListItemText 
              primary={item.text}
              sx={{
                color: 'white',
                '& .MuiListItemText-primary': {
                  fontSize: '1rem',
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-4px',
                    left: '0',
                    width: location.pathname === item.path ? '100%' : '0%',
                    height: '2px',
                    backgroundColor: '#fff',
                    transition: 'width 0.3s ease-in-out'
                  },
                  '&:hover::after': {
                    width: '100%'
                  }
                }
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar 
      position="fixed" 
      sx={{
        background: isScrolled ? 'rgba(18, 18, 18, 0.8)' : 'transparent',
        backdropFilter: isScrolled ? 'blur(10px)' : 'none',
        boxShadow: 'none',
        transition: 'all 0.3s ease-in-out'
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <Link to="/">
            <img 
              src={logo} 
              alt="Omnilabs" 
              style={{ 
                height: '40px',
                cursor: 'pointer',
                transition: 'opacity 0.2s ease-in-out',
              }} 
            />
          </Link>

          {/* Desktop Menu */}
          {!isMobile && (
            <Box sx={{ display: 'flex', gap: '20px' }}>
              {menuItems.map((item) => (
                <NavButton 
                  key={item.text}
                  to={item.path} 
                  active={location.pathname === item.path}
                >
                  {item.text}
                </NavButton>
              ))}
            </Box>
          )}
        </Box>

        {/* Mobile Menu Icon */}
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ ml: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}

        {/* Mobile Drawer */}
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
            disableScrollLock: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 250,
              backgroundColor: 'rgba(18, 18, 18, 0.95)',
              borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
              height: 'auto',
              maxHeight: '100%',
              overflow: 'hidden'
            },
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }
          }}
        >
          {drawer}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

const NavButton = ({ to, active, children }) => (
  <Button
    component={Link}
    to={to}
    sx={{
      color: 'white',
      textTransform: 'none',
      fontSize: '1rem',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: active ? '100%' : '0%',
        height: '2px',
        backgroundColor: '#fff',
        transition: 'width 0.3s ease-in-out'
      },
      '&:hover::after': {
        width: '100%'
      }
    }}
  >
    {children}
  </Button>
);

export default Navbar;