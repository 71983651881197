import { Container, Typography, Box, Paper } from '@mui/material';
import { motion } from 'framer-motion';

const PrivacyPolicy = () => {
  return (
    <Container className="page-container">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Paper
          sx={{
            background: 'rgba(255, 255, 255, 0.03)',
            backdropFilter: 'blur(10px)',
            borderRadius: '16px',
            padding: { xs: 2, md: 4 },
            marginBottom: 4,
          }}
        >
          <Typography variant="h2" className="heading" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="subtitle1" color="rgba(255, 255, 255, 0.7)" gutterBottom>
            Effective Date: 01.03.2025
          </Typography>

          <Box sx={{ mt: 4 }}>
            <Typography paragraph color="rgba(255, 255, 255, 0.9)">
              Omnilabs LTD ("we," "us," or "our") is committed to respecting your privacy. This Privacy Policy explains our practices regarding the information we collect from users of our mobile application(s) ("App" or "Apps") and website ("Site").
            </Typography>

            <Typography paragraph color="rgba(255, 255, 255, 0.9)">
              By accessing or using our Apps or Site, you consent to the collection and use of your information as described in this Privacy Policy. If you do not agree, please discontinue use of our Apps and Site immediately.
            </Typography>

            <Typography variant="h5" sx={{ mt: 4, mb: 2, color: '#fff' }}>
              1. Information We Collect
            </Typography>
            
            <Typography variant="h6" sx={{ mt: 3, mb: 1, color: '#fff' }}>
              1.1 Limited Collection
            </Typography>
            <Typography paragraph color="rgba(255, 255, 255, 0.9)">
              We do not collect any information beyond what is strictly necessary for:
            </Typography>
            <ul style={{ color: 'rgba(255, 255, 255, 0.9)' }}>
              <li>App Functioning: Enabling core App features and maintaining service quality.</li>
              <li>Diagnostics: Identifying and fixing bugs, improving performance, and understanding usage at a high level (e.g., crash reports).</li>
            </ul>
            <Typography paragraph color="rgba(255, 255, 255, 0.9)">
              We do not gather personally identifiable information such as your name, email address, or location unless explicitly stated and required for specific functionalities (e.g., creating an account or processing a specific user request).
            </Typography>

            <Typography variant="h6" sx={{ mt: 3, mb: 1, color: '#fff' }}>
              1.2 Usage and Diagnostic Data
            </Typography>
            <Typography paragraph color="rgba(255, 255, 255, 0.9)">
              When you use our App or Site, we may automatically collect:
            </Typography>
            <ul style={{ color: 'rgba(255, 255, 255, 0.9)' }}>
              <li>Device Information: Basic details like device type, operating system, and unique device identifiers, if necessary for diagnostics.</li>
              <li>App Analytics: General, anonymized usage data that helps us understand how users engage with our Apps, allowing us to optimize performance and user experience.</li>
            </ul>

            <Typography variant="h5" sx={{ mt: 4, mb: 2, color: '#fff' }}>
              2. How We Use Your Information
            </Typography>
            <Typography paragraph color="rgba(255, 255, 255, 0.9)">
              We use the limited information we collect solely for the following purposes:
            </Typography>
            <ul style={{ color: 'rgba(255, 255, 255, 0.9)' }}>
              <li>App Functionality: Ensuring the App operates as intended, including core features and necessary background services.</li>
              <li>Diagnostics and Troubleshooting: Identifying errors, crashes, and other technical issues to maintain a stable user experience.</li>
              <li>Performance Improvements: Analyzing usage trends (in aggregate) to enhance features, performance, and user satisfaction.</li>
            </ul>

            <Typography variant="h5" sx={{ mt: 4, mb: 2, color: '#fff' }}>
              3. Sharing of Information
            </Typography>
            <Typography paragraph color="rgba(255, 255, 255, 0.9)">
              We do not sell, rent, or share your information with any third parties. Specifically:
            </Typography>
            <ul style={{ color: 'rgba(255, 255, 255, 0.9)' }}>
              <li>No Third-Party Access: We do not provide or grant access to your information to advertisers, analytics companies, or external service providers beyond what is essential to maintain our services (e.g., hosting, crash reporting). Where such essential services are used, they are contractually prohibited from using the data for any other purposes.</li>
              <li>No Disclosure Without Consent: We will not disclose your information to any other entity without your explicit consent, except if required by law or legal process.</li>
            </ul>

            <Typography variant="h5" sx={{ mt: 4, mb: 2, color: '#fff' }}>
              4. Data Security
            </Typography>
            <Typography paragraph color="rgba(255, 255, 255, 0.9)">
              We employ reasonable administrative, technical, and physical safeguards to protect the data we do collect from unauthorized access, alteration, or destruction. However, no transmission method over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </Typography>

            <Typography variant="h5" sx={{ mt: 4, mb: 2, color: '#fff' }}>
              5. Children's Privacy
            </Typography>
            <Typography paragraph color="rgba(255, 255, 255, 0.9)">
              Our Apps and Site are not directed to children under the age of 13 (or other age as stipulated by local law), and we do not knowingly collect personal information from children.
            </Typography>

            <Typography variant="h5" sx={{ mt: 4, mb: 2, color: '#fff' }}>
              6. Changes to This Privacy Policy
            </Typography>
            <Typography paragraph color="rgba(255, 255, 255, 0.9)">
              We may update this Privacy Policy from time to time. When we do, we will:
            </Typography>
            <ul style={{ color: 'rgba(255, 255, 255, 0.9)' }}>
              <li>Post the updated policy on our Site.</li>
              <li>Update the "Effective Date" at the top of this document.</li>
            </ul>
            <Typography paragraph color="rgba(255, 255, 255, 0.9)">
              We encourage you to review this Privacy Policy periodically for any changes. Your continued use of our Apps or Site after such modifications will constitute your acknowledgment of the changes and agreement to abide by the updated policy.
            </Typography>
          </Box>
        </Paper>
      </motion.div>
    </Container>
  );
};

export default PrivacyPolicy; 