export const getGalleryImages = (appId) => {
  const context = require.context('../../public/app-images', true);
  const galleryRegex = new RegExp(`${appId}/${appId}-\\d+\\.(png|jpg|jpeg)$`, 'i');
  
  return context.keys()
    .filter(path => galleryRegex.test(path))
    .map(path => `/app-images${path.substring(1)}`)
    .sort((a, b) => {
      const aNum = parseInt(a.match(/\d+/)[0]);
      const bNum = parseInt(b.match(/\d+/)[0]);
      return aNum - bNum;
    });
}; 