import { Container, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';

const Contact = () => {
  return (
    <Container className="page-container">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h2" align="center" sx={{ mb: 4, color: '#fff' }}>
          Contact Us
        </Typography>
        
        <Box
          sx={{
            maxWidth: '800px',
            margin: '0 auto',
            padding: { xs: 2, md: 4 },
            background: 'rgba(255, 255, 255, 0.03)',
            borderRadius: '16px',
            backdropFilter: 'blur(10px)',
            textAlign: 'center',
          }}
        >
          <img
            src="/contact-us.png"
            alt="Mail Icon"
            style={{ maxWidth: '100%', height: 'auto', opacity: 0.8 }}
          />
        </Box>

        <Typography variant="body1" align="center" sx={{ mt: 4, color: '#fff' }}>
          Please contact us via email at{' '}
          <a 
            href="mailto:support@omni-labs.net" 
            style={{ color: '#fff', textDecoration: 'none' }}
          >
            support@omni-labs.net
          </a>
        </Typography>
      </motion.div>
    </Container>
  );
};

export default Contact;
